
<template>
    <div>评论列表</div>
</template>

<script>

export default {
  components: {},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
</style>

